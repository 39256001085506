import React from 'react';
import { Header } from './Header';
import { Box, Paper, Typography } from '@mui/material';
import { titleTextColor } from './theme';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import styled from 'styled-components';

export const ServicesPage = () => {
  return (
    <StyledServicesPage>
      <Header />
      <Box pt={2} display='flex' flexDirection='column' alignItems='center' gap={1}>
        <Box bgcolor='#1977d3' borderRadius='50%'>
          <PlumbingIcon style={{ color: 'white', width: '60px', height: '60px', padding: '8px' }} />
        </Box>
        <Typography variant='h4' color={titleTextColor}>Services</Typography>
        <Paper className='services'>
          <Box p={2} display='flex'>
            <ServicesList1 />
            <ServicesList2 />
          </Box>
        </Paper>
        <Paper className='mobile-services'>
          <Box p={2} display='flex' flexDirection='column'>
            <ServicesList1 />
            <ServicesList2 />
          </Box>
        </Paper>
      </Box>
    </StyledServicesPage>
  );
}

const StyledServicesPage = styled(Box)`
  .mobile-services {
    display: none;
  }

  @media(max-width: 600px) {
    .services {
      display: none;
    }

    .mobile-services {
      display: block;

      ul {
        margin: 0;
      }
    }
  }
`

const ServicesList1 = () => {
  return (
    <ul>
      <li>Faucet repair and replacement</li>
      <li>Plumbing</li>
      <li>Remodels: Kitchens, bath, etc.</li>
      <li>Sewers</li>
      <ul>
        <li>
          Excavation and installation of new sewer lines
        </li>
        <li>
          Replacement of bad sewer lines
        </li>
      </ul>
      <li>Gas Lines</li>
      <ul>
        <li>Repairs</li>
        <li>
          Whole house gas lines
        </li>
        <li>Barbeques</li>
        <li>Firepits</li>
      </ul>
    </ul>
  );
}

const ServicesList2 = () => {
  return (
    <ul>
      <li>Water Services</li>
      <ul>
        <li>Installation of new water services
          (i.e. toilets, sinks, etc.)</li>
        <li>Replacement of leaking or old water services
        </li>
      </ul>
      <li>New Contruction
      </li>
      <ul>
        <li>Custom Homes
        </li>
        <li>Commercial</li>
        <li>Residential</li>
      </ul>
      <li>Service Calls
      </li>
      <ul>
        <li>Small Drain Line Cleaning (lavatories, tubs, showers, kitchens, sinks, floor drains)
        </li>
        <li>Washer Replacement
        </li>
        <li>Garbage Disposals
        </li>
        <li>Sump Pumps
        </li>
        <li>Fixture Replacement
        </li>
        <li>Water Heaters
        </li>
      </ul>
    </ul>
  );
}