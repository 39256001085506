import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { Header } from './Header';
import { titleTextColor } from './theme';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import styled from 'styled-components';

export const AboutPage = () => {
  return (
    <StyledAboutPage>
      <Header />
      <Box display='flex' justifyContent='center' width='100%'>
        <Box pt={1} className='about-wrapper' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <PersonPinIcon style={{ color: '#1977d3', width: '100px', height: '100px' }} />
          <Typography variant='h4' color={titleTextColor}>About</Typography>
          <Typography >
            Established in 1978, Crandell Plumbing is a trusted, family-owned business with 45 years of dedicated service. Whether it's routine maintenance or new installations, Crandell Plumbing ensures each job is done right the first time.
          </Typography>
          <Box pt={4}>
            <Paper>
              <Box p={2}>
                <Typography variant='body1'>
                  We are always commited to:
                </Typography>
                <ul>
                  <li>Exeptional customer service and satisfaction</li>
                  <li>Maintaining good, honest relationships with customers</li>
                  <li>Prompt response time</li>
                  <li>Making sure the job is done right</li>
                </ul>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </StyledAboutPage>
  )
}

const StyledAboutPage = styled(Box)`
  .about-wrapper {
    width: 50%;
  }

  @media(max-width: 900px) {
    .about-wrapper {
      width: 90%;
    }
  }
`