import { Box } from '@mui/material';
import React from 'react';
import { Header } from './Header';
import Image1 from './assets/IMG_3188.jpg';
import Image2 from './assets/IMG_4266.jpg';
import Image3 from './assets/IMG_4267.jpg';
import Image4 from './assets/IMG_4458.jpg';
import Image5 from './assets/job.jpg';
import Image6 from './assets/job2.jpg';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import styled from 'styled-components';

export const PhotoGalleryPage = () => {
  const images = [
    {
      original: Image1,
      thumbnail: Image1
    },
    {
      original: Image2,
      thumbnail: Image2
    },
    {
      original: Image3,
      thumbnail: Image3
    },
    {
      original: Image4,
      thumbnail: Image4
    },
    {
      original: Image5,
      thumbnail: Image5
    },
    {
      original: Image6,
      thumbnail: Image6
    }
  ]
  return (
    <Box>
      <Header />
      <GalleryWrapper p={2}>
        <ImageGallery items={images} />
      </GalleryWrapper>
    </Box>
  )
}

const GalleryWrapper = styled(Box)`
  & .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(80vh - 80px);
  }
`