import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { HomePage } from './HomePage';
import reportWebVitals from './reportWebVitals';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PhotoGalleryPage } from './PhotoGalleryPage';
import { ContactPage } from './ContactPage';
import { AboutPage } from './AboutPage';
import { ServicesPage } from './ServicesPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: '/services',
    element: <ServicesPage />
  },
  {
    path: '/photos',
    element: <PhotoGalleryPage />
  },
  {
    path: '/contact',
    element: <ContactPage />
  },
  {
    path: '/about',
    element: <AboutPage />
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
