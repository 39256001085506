import React from 'react';
import { Box, Button, Link, List, ListItem, Paper, Typography } from '@mui/material';
import { Header } from './Header';
import Truck from './assets/truck.png';
import styled from 'styled-components';
import { titleTextColor } from './theme';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

export const HomePage = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Header />
      <HeroSection>
        <HeroWrapper p={3} gap={4}>
          <Box flexBasis='60%' display='flex' alignItems='center'>
            <img src={Truck} />
          </Box>
          <HeroServices />
        </HeroWrapper>
      </HeroSection>
      <MobileHeroSection>
        <MobileHeroWrapper>
          <Box display='flex' justifyContent='center'>
            <img src={Truck} width='90%' />
          </Box>
          <Box p={2}>
            <Paper>
              <Box p={2}>
                <HeroServices />
              </Box>
            </Paper>
          </Box>
        </MobileHeroWrapper>
      </MobileHeroSection>
      <LowerSection>
        <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
          <Button variant='contained' style={{
            borderRadius: '50%', width: '60px'
          }}
            onClick={() => {
              navigate('/services');
            }}
          >
            <PlumbingIcon style={{ width: '40px', height: '50px', color: 'white' }} />
          </Button>
          <Link onClick={() => {
            navigate('/services')
          }}>
            <Typography fontWeight={800} variant='h5' color={titleTextColor}>
              Services
            </Typography>
          </Link>
          <Typography variant='body1'>
            View a list of services
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
          <Button variant='contained' style={{
            borderRadius: '50%', width: '60px'
          }}
            onClick={() => {
              navigate('/photos')
            }}
          >
            <PhotoLibraryIcon style={{ width: '40px', height: '50px', color: 'white' }} />
          </Button>
          <Link onClick={() => {
            navigate('/photos')
          }}>
            <Typography fontWeight={800} variant='h5' color={titleTextColor}>
              Photo Gallery
            </Typography>
          </Link>
          <Typography variant='body1'>
            View images of our recent projects
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
          <Button variant='contained' style={{
            borderRadius: '50%', width: '60px'
          }}
            onClick={() => {
              navigate('/contact')
            }}
          >
            <PhoneIcon style={{ width: '40px', height: '50px', color: 'white' }} />
          </Button>
          <Link onClick={() => {
            navigate('/contact')
          }}>
            <Typography fontWeight={800} variant='h5' color={titleTextColor}>
              Contact Us
            </Typography>
          </Link>
          <Typography variant='body1'>
            <Box display='flex' gap={1}>
              <Typography>Give us a call:</Typography>
              <a href="tel:+13037331204">
                (303) 733-1204
              </a>
            </Box>
          </Typography>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center' gap={1}>
          <Button variant='contained' style={{
            borderRadius: '50%', width: '60px'
          }}
            onClick={() => {
              navigate('/about')
            }}
          >
            <PersonIcon style={{ width: '40px', height: '50px', color: 'white' }} />
          </Button>
          <Link onClick={() => {
            navigate('/about')
          }}>
            <Typography fontWeight={800} variant='h5' color={titleTextColor}>
              About Us
            </Typography>
          </Link>
          <Typography variant='body1'>
            In business since 1978
          </Typography>
        </Box>
      </LowerSection >
    </Box >
  );
}

const HeroServices = () => {
  const navigate = useNavigate();
  return (
    <Box display='flex' flexDirection='column' flexBasis='30%' gap={2}>
      <Typography variant='h4' fontWeight='800' color={titleTextColor}>
        Home Plumbing Services
      </Typography>
      <Typography variant='h6' fontWeight='800'>
        45 years of experience serving Denver and surrounding areas
      </Typography>
      <Typography >
        Established in 1978, Crandell Plumbing is a trusted, family-owned business with 45 years of dedicated service. Whether it's routine maintenance or new installations, Crandell Plumbing ensures each job is done right the first time.
      </Typography>
      <Box pt={2} display='flex' gap={2} justifyContent='center' alignItems='center'>
        <Button onClick={() => {
          navigate('/contact');
        }} variant='contained'>Contact us</Button>
        <Typography>
          📞
        </Typography>
        <a href="tel:+13037331204">
          (303) 733-1204
        </a>
      </Box>
      <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
        <ul>
          <li>Drains</li>
          <li>Plumbing</li>
          <li>Remodels</li>
          <li>Sewers</li>
        </ul>
        <ul>
          <li>Gas lines</li>
          <li>New construction</li>
          <li>Faucet repair</li>
          <li>Water heaters</li>
        </ul>
      </Box>
    </Box>
  );
}

const HeroSection = styled(Box)`
  img {
    width: 100%;
    filter: drop-shadow(0 0 40px rgba(255, 255, 255, 1));
  }
`;

const MobileHeroSection = styled(Box)`
  display: none;
  @media(max-width: 1000px) {
    display: block;
  }
`;

const HeroWrapper = styled(Box)`
  display: flex;
  background: linear-gradient(to right, #ADCDDD, transparent);
  @media(max-width: 1000px) {
    display: none;
  }
`;

const MobileHeroWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 8px;

  img {
    width: 100%;
    filter: drop-shadow(0 0 5px #CF9259);
  }
`;

const LowerSection = styled(Box)`
  padding-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  a:hover {
    cursor: pointer;
  }

  padding-top: 16px;

  @media(max-width: 1000px) {
    padding-top: 0;

    & > div {
      flex: 1 1 calc(50% - 20px);
      padding-top: 12px;
    }

    p {
      display: none;
    }
  }
`;
