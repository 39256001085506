import React, { useEffect } from 'react';
import { Header } from './Header';
import { Box, Paper, Typography } from '@mui/material';
import { titleTextColor } from './theme';
import TruckImage from './assets/truck2.jpg';
import styled from 'styled-components';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export const ContactPage = () => {
  return (
    <StyledContactPage>
      <Header />
      <Box p={2} display='flex' justifyContent='center' position='relative'>
        <Box p={2} display='flex' flexDirection='column' alignItems='center'>
          <Box borderRadius='50%' bgcolor='#1977d3'>
            <LocalPhoneIcon style={{ width: '50px', height: '50px', color: 'white', padding: '8px' }} />
          </Box>
          <Typography variant='h4' color={titleTextColor}>Contact</Typography>
          <Box display='flex' gap={1}>
            <Typography variant='body1' color={titleTextColor}>Phone: </Typography>

            <a href="tel:+13037331204">
              <Typography variant='body1'>(303) 733-1204</Typography>
            </a>
          </Box>
          <Typography variant='body1'>Email: info@crandellplumbing.com</Typography>
        </Box>
      </Box>
    </StyledContactPage >
  );
}

const StyledContactPage = styled(Box)`

`;

const StyledImage = styled.img`
  filter: drop-shadow(0 0 6px grey);
  width: 60%;

  @media(max-width: 1200px) {
    width: 90%;
  }
`;