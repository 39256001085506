import { AppBar, Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { titleTextColor } from './theme';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoImage from './assets/logo.png';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import PersonIcon from '@mui/icons-material/Person';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileNavRef = useRef<HTMLButtonElement>(null);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const handleMobileNavClose = () => {
    setMobileNavOpen(false);
  }
  return (
    <StyledHeader>
      <AppBar position='static' color='transparent'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Box p={1} gap={2} display='flex' alignItems='center'>
            <Button style={{ backgroundColor: 'transparent' }} disableRipple disableFocusRipple onClick={() => {
              navigate('/')
            }}>
              <Logo />
            </Button>
            <Typography variant='h6' color={titleTextColor} fontWeight='800'>
              Crandell Plumbing, Inc.
            </Typography>
          </Box>
          <Box className='nav' display='flex' gap={4} pr={4} alignItems='baseline'>
            <Box className='phone-number' display='flex' gap={1}>
              <Typography>📞 Give us a call: </Typography>
              <a href="tel:+13037331204">
                (303) 733-1204
              </a>
            </Box>
            <Button variant={location.pathname === '/' ? 'contained' : 'text'} onClick={() => {
              navigate('/');
            }}>Home</Button>
            <Button variant={location.pathname === '/services' ? 'contained' : 'text'} onClick={() => {
              navigate('/services')
            }}>Services</Button>
            <Button onClick={() => {
              navigate('/photos');
            }} variant={location.pathname === '/photos' ? 'contained' : 'text'}>Photos</Button>
            <Button variant={location.pathname === '/contact' ? 'contained' : 'text'} onClick={() => {
              navigate('/contact')
            }}>Contact</Button>
            <Button variant={location.pathname === '/about' ? 'contained' : 'text'} onClick={() => {
              navigate('/about')
            }}>About us</Button>
          </Box>
          <Box p={2} className='mobile-nav'>
            <IconButton ref={mobileNavRef} onClick={() => {
              setMobileNavOpen(true);
            }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={mobileNavRef.current}
              open={mobileNavOpen}
              onClose={handleMobileNavClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => {
                navigate('/services');
                handleMobileNavClose();
              }}>
                <Box display='flex' gap={2}>
                  <PlumbingIcon />
                  Services
                </Box>
              </MenuItem>
              <MenuItem onClick={() => {
                navigate('/photos');
                handleMobileNavClose();
              }}>
                <Box display='flex' gap={2}>
                  <InsertPhotoIcon />
                  Photos
                </Box>
              </MenuItem>
              <MenuItem onClick={() => {
                navigate('/contact');
                handleMobileNavClose();
              }}>
                <Box display='flex' gap={2}>
                  <LocalPhoneIcon />
                  Contact
                </Box>
              </MenuItem>
              <MenuItem onClick={() => {
                navigate('/about');
                handleMobileNavClose();
              }}>
                <Box display='flex' gap={2}>
                  <PersonIcon />
                  About
                </Box>
              </MenuItem>

            </Menu>
          </Box>
        </Box>
      </AppBar>
    </StyledHeader>
  )
}

const Logo = () => {
  return (
    <Box>
      <img width={75} height={75} src={LogoImage} />
    </Box>
  )
}

const StyledHeader = styled(Box)`
  .mobile-nav {
    display: none;
  }

  @media(max-width: 950px) {
    .nav {
      display: none;
    }

    .mobile-nav {
      display: block;
    }
  }

  @media(max-width: 1300px) {
    .phone-number {
      display: none;
    }
  }
`;